<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">Users</h1>
						</div>
						<div>
							<router-link :to="{
								name: 'CreateBatchBooking',
							}" class="btn btn-outline-primary  mr-2 ml-2" v-if="userHasPermission('users011')">Create Batch Booking</router-link>
							<router-link :to="{ name: 'NotifyUser' }" class="btn btn-outline-primary mr-2"  v-if="userHasPermission('users013')">Notify</router-link>
							<router-link to="/users/add" class="btn btn-primary" v-if="userHasPermission('users001')">Add User</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header justify-content-between">
							<div class="input-group input-group-flush d-flex flex-row-reverse">
								<input v-model.trim="search" class="form-control list-search" type="search" placeholder="Search"
									@keyup.enter.prevent="fetchUsers(true)" />
								<span class="input-group-text border-0">
									<i class="fe fe-search pointer" @click.prevent="fetchUsers(true)"></i>
								</span>
							</div>
							<div class="col-auto" v-if="users.length">
								<v-datepicker style="width: 100%" v-model="filter.range" placeholder="Filter by date"
									range></v-datepicker>
							</div>
						</div>

						<div class="d-flex justify-content-between align-items-center">
							<div class="p-3">
								<label for="status_active" class="d-block-inline px-2">
									<input type="radio" v-model="statusFilter" name="status" value="active" id="status_active" />
									Active
								</label>
								<label for="status_inactive" class="d-block-inline px-2">
									<input type="radio" v-model="statusFilter" name="status" value="inactive" id="status_inactive" />
									Inactive
								</label>
							</div>
							<div class="d-flex justify-content-between align-items-center">
								<div class="mr-4">
									<download-button class="cursor-pointer" v-if="users.length" :downloading="downloadingReport"
										label="Download Report" @download="downloadReport()"></download-button>
								</div>
							</div>
						</div>

						<b-table striped hover selectable show-empty responsive :items="users" :fields="fields"
							:current-page="currentPage" :busy="fetchingUsers" @row-clicked="viewUser">
							<template #table-busy>
								<div class="d-flex flex-column justify-content-center align-items-center">
									<div class="spinner-border" role="status"></div>
									<p class="text-center mt-2"><strong>Loading...</strong></p>
								</div>
							</template>

							<template #empty>
								<p class="text-center text-secondary">{{ search ? `No records found for search value: "${search}"` : 'No records available'}}</p>
							</template>

							<template #cell(user)="data">
								<p class="font-weight-bold" style="line-height: 1">
									<router-link class="sh-page-link" :to="{ name: 'ShowUser', params: { userId: data.item.id } }">
										{{ `${data.item.fname} ${data.item.lname}` }}
									</router-link>
								</p>
								<p style="line-height: 1">
									<router-link class="sh-page-link" :to="{ name: 'ShowUser', params: { userId: data.item.id } }">{{
										data.item.email }}</router-link>
								</p>
								<p style="line-height: 1">
									<router-link class="sh-page-link" :to="{ name: 'ShowUser', params: { userId: data.item.id } }">{{
										data.item.phone }}</router-link>
								</p>
							</template>
							<template #cell(created_at)="data">
								{{ data.value | date('dd/MM/yyyy') }}
							</template>
							<template #cell(car_owner)="data">
								{{ data.value === 1 ? 'Yes' : 'No' }}
							</template>
							<template #cell(dob)="data">
								{{ data.value }}
							</template>
							<template #cell(wallet_balance)="data">
								₦{{ data.item.wallet.amount | money }}
							</template>
							<template #cell(company_balance)="data">
								₦{{ data.item.wallet.credit_amount | money }}
							</template>
							<template #cell(avatar)="data">
								<user-avatar :user="data.item" size="sm"></user-avatar>
							</template>
							<template #cell(status)="data">
								<span class="sh-page-link badge text-white" :class="data.item.active == 1 ? 'bg-success' : 'bg-danger'">{{
									data.item.active == 1 ? 'Active' : 'Inactive' }}</span>
							</template>
						</b-table>

						<div class="card-footer" v-if="totalRecords">
							<b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"></b-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
import UserAvatar from '@/components/core/UserAvatar.vue'
import moment from 'moment'
import { ExportToCsv } from 'export-to-csv'
import DownloadButton from '@/components/core/DownloadButton.vue'
export default {
  components: {
    UserAvatar,
    DownloadButton
  },
  data() {
    return {
      fields: [
        {
          key: 'avatar',
          label: 'Avatar'
        },
        {
          key: 'user',
          label: 'User'
        },
        {
          key: 'created_at',
          label: 'Date Joined'
        },
        {
          key: 'wallet_balance',
          label: 'Wallet Balance'
        },
        {
          key: 'company_balance',
          label: 'Company Balance'
        },

        {
          key: 'status',
          label: 'Status'
        }
      ],
      perPage: 20,
      currentPage: 1,
      totalRecords: 0,
      users: [],
      fetchingUsers: true,
      downloadingReport: false,
      search: '',
      filter: {
        range: []
      },
      statusFilter: 'active'
    }
  },
  created() {
    this.fetchUsers()
  },
  computed: {
    filteredUsers() {
      const search = this.search.toLowerCase()
      let users = this.users.filter((user) => {
        return (
          user.fname.toLowerCase().includes(search) ||
          user.lname.toLowerCase().includes(search) ||
          user.email.toLowerCase().includes(search) ||
          user.phone.toLowerCase().includes(search)
        )
      })
      if (this.dateFilterValid) {
        const dateRange = this.filter.range.map((date) =>
          moment(date).format('YYYY-MM-DD')
        )
        users = users.filter(
          (user) =>
            user.created_at.split(' ')[0] >= dateRange[0] &&
            user.created_at.split(' ')[0] <= dateRange[1]
        )
      }
      return users
    },
    dateRange() {
      return this.filter.range.map((date) => moment(date).format('YYYY-MM-DD'))
    },
    dateFilterValid() {
      return (
        this.filter.range.length &&
        this.filter.range.filter((date) => !!date).length > 0
      )
    }
  },
  watch: {
    currentPage() {
      this.fetchUsers()
    },
    search(value) {
      if (!value) {
        this.fetchUsers(true)
      }
    },
    dateFilterValid() {
      this.fetchUsers(true)
    },
    dateRange(newValue, oldValue) {
      if (newValue[0] !== newValue[1] || oldValue[0] !== oldValue[1]) {
        this.fetchUsers(true)
      }
    },
    statusFilter() {
      this.fetchUsers(true)
    }
  },
  methods: {
    fetchUsers(reset = false) {
      if (reset) {
        this.currentPage = 1
      }

      this.fetchingUsers = true
      const dateRange = this.filter.range.map((date) =>
        moment(date).format('YYYY-MM-DD')
      )
      const url = `/v1/users${this.search ? '/search' : ''}?limit=${this.perPage}&page=${this.currentPage}&metadata=true&sort[id]=desc&related=wallet&status=${this.statusFilter}`
      const filter_url = `/v1/users?metadata=true&page=${this.currentPage}&limit=${this.perPage}&start_date_filter=${dateRange[0]}&end_date_filter=${dateRange[1]}`
      const request = this.search
        ? this.axios.post(url, {
          user: this.search || '',
          creation_date_start: this.dateFilterValid ? dateRange[0] : null,
          creation_date_end: this.dateFilterValid ? dateRange[1] : null
        })
        : this.dateFilterValid ? this.axios.get(filter_url) : this.axios.get(url)
      request
        .then((res) => {
          this.users = res.data.data
          this.totalRecords = res.data?.metadata?.total
          return this.users
        })
        .finally(() => (this.fetchingUsers = false))
    },

    viewUser(user) {
      this.$router.push({ name: 'ShowUser', params: { userId: user.id } })
    },

    async downloadReport() {
      const dateRange = this.filter.range.map((date) => moment(date).format('YYYY-MM-DD'))
      try {
        this.$Progress.start()
        this.downloadingReport = true
        const results = await this.axios.get(`/v1/users?metadata=true&page=${this.currentPage}&limit=${this.totalRecords}&start_date_filter=${dateRange[0]}&end_date_filter=${dateRange[1]}&status=${this.statusFilter}`)
        const users = results.data.data
        this.$Progress.finish()
        const csvData = users.map((data) => {
          return {
            name: data.fname + ' ' + data.lname,
            email: data.email,
            phone_number: data.phone,
            company_balance: data.wallet.credit_amount,
            wallet_balance: data.wallet.amount,
            date_joined: data.created_at,
            car_owner: data.car_owner === '0' ? 'No' : 'Yes',
            status: data.active === '1' ? 'Active' : 'InActive'
          }
        })

        const csvParams = {
          fieldSeparator: ',',
          filename: Array.isArray(this.dateRange) && !this.dateRange.length ? 'Users List' : `Users List for ${this.dateRange[0]} to ${this.dateRange[1]}`,
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          title: Array.isArray(this.dateRange) && !this.dateRange.length ? 'Users List' : `Users List for ${this.dateRange[0]} to ${this.dateRange[1]}`,
          useTextFile: false,
          useBom: true,
          headers: [
            'Name',
            'Email Address',
            'Phone number',
            'Company Balance',
            'Wallet Balance',
            'Date Joined',
            'Car Owner',
            'Status'
          ]
        }

        const csvExporter = new ExportToCsv(csvParams)

        csvExporter.generateCsv(csvData)

        this.$swal({
          icon: 'success',
          title: 'Report downloaded',
          text: 'Report has been downloaded successfully',
          showCloseButton: true
        })
      } catch (error) {
        this.$Progress.fail()
      } finally {
        this.downloadingReport = false
      }
    }
  }
}
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
</style>
